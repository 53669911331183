<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Dropdown
              v-model="selectedNomesVisitantes"
              :options="nomesVisitantes"
              optionValue="id"
              optionLabel="nome"
              placeholder="Escolha um visitante"
              emptyFilterMessage="Nenhum resultado encontrado."
              class="p-button-success p-mr-2 p-mb-2"
              id="visitante_id"
              :filter="true"
              :showClear="true"
              @change="listarVisitas"
            />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              :disabled="!selectedNomesVisitantes"
              @click="openNew"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="visitas"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedVisitas"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} visitantes"
        >
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Entrada de Visitantes</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <Column header="Cracha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cracha</span>
              {{ slotProps.data.cracha.nome }} | REF :
              {{ slotProps.data.cracha.referencia }} -
              {{ slotProps.data.cracha.andar }} º Andar
            </template>
          </Column>
          <Column field="destino" header="Destino" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Destino</span>
              {{ slotProps.data.destino }}
            </template>
          </Column>
          <Column field="motivo" header="Motivo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Motivo</span>
              {{ slotProps.data.motivo }}
            </template>
          </Column>
          <Column field="dt_entrada" header="Entrada" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Entrada</span>
              {{ formatarDatas(slotProps.data.dt_entrada) }}
            </template>
          </Column>
          <Column field="dt_saida" header="Saída" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Saída</span>
              {{ formatarDatas(slotProps.data.dt_saida) }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editVisita(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmdeleteVisita(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="visitaDialog"
          :style="{ width: '850px' }"
          :header="titleDialog"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="visitante">Visitante</label>
            <Dropdown
              v-model="selectedNomesVisitantes"
              :options="nomesVisitantesDisponiveis"
              optionValue="id"
              optionLabel="nome"
              placeholder="Selecione um visitante"
              :filter="true"
              :showClear="true"
              id="visitante_id"
            />
          </div>
          <div class="p-field">
            <label for="cracha_id">Cracha</label>

            <Dropdown
              v-model="visita.cracha.id"
              :options="crachas"
              optionDisabled="status"
              optionValue="id"
              optionLabel="andar"
              placeholder="Selecione um cracha"
              :filter="true"
              :showClear="true"
              id="cracha_id"
            >
              <template #option="slotProps">
                <div class="country-item">
                  <div>
                    {{ slotProps.option.nome }} | {{ slotProps.option.andar }} |
                    REF:
                    {{ slotProps.option.referencia }}
                  </div>
                </div>
              </template>
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !visita.cracha.id"
              >Cracha é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="destino">Destino</label>
            <InputText
              id="destino"
              v-model.trim="visita.destino"
              required="true"
              placeholder="Digite o destino"
              maxlength="100"
              :class="{ 'p-invalid': submitted && !visita.destino }"
            />
            <small class="p-invalid" v-if="submitted && !visita.destino"
              >Destino é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="motivo">Motivo</label>
            <Textarea
              id="motivo"
              v-model.trim="visita.motivo"
              required="true"
              placeholder="Digite o motivo"
              rows="3"
              :class="{ 'p-invalid': submitted && !visita.motivo }"
            />
            <small class="p-invalid" v-if="submitted && !visita.motivo"
              >Motivo é obrigatório</small
            >
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendVisita"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteVisitaDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visita"
              >Tem certeza de que deseja excluir o registro
              <b>{{ visita.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteVisita"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteVisitaDialog = false"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteVisitasDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visita"
              >Tem certeza de que deseja excluir os registros selecionados
              ?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteSelectedVisitas"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteVisitasDialog = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";
import VisitaService from "@/service/Visita/VisitaService";
import VisitanteService from "@/service/Visitante/VisitanteService";
import CrachaService from "@/service/Cracha/CrachaService";
export default {
  data() {
    return {
      visitas: null,
      visitaDialog: false,
      deleteVisitaDialog: false,
      deleteVisitasDialog: false,
      nomesVisitantes: null,
      nomesVisitantesDisponiveis: null,
      selectedNomesVisitantes: null,
      selectedCracha: null,
      crachas: null,
      visita: {
        id: null,
        destino: null,
        motivo: null,
        visitante: {
          id: null,
        },
        cracha: {
          id: null,
          nome: null,
          andar: null,
          referencia: null,
        },
        dt_entrada: null,
      },
      selectedVisitas: null,
      filters: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
      selectedGroupedCity: null,
      groupedAndares: [
        {
          andar: "Térreo",
          items: [],
        },
        {
          andar: "1° Andar",
          items: [],
        },
        {
          andar: "2° Andar",
          items: [],
        },
        {
          andar: "3° Andar",
          items: [],
        },
        {
          andar: "4° Andar",
          items: [],
        },
      ],
    };
  },
  visitaService: null,
  visitanteService: null,
  authService: null,
  crachaService: null,
  created() {
    this.authService = new AuthService();
    this.visitaService = new VisitaService();
    this.visitanteService = new VisitanteService();
    this.crachaService = new CrachaService();
  },
  mounted() {
    this.authService.checarToken(this.listarNomes());
    this.authService.checarToken(this.listarCrachas());
    this.authService.checarToken(this.listarNomesDisponiveis());
  },
  methods: {
    listarCrachas() {
      this.crachaService.listar().then((data) => {
        this.crachas = data;

        this.groupedAndares[0].items = this.crachas.filter(
          (cracha) => cracha.andar == "Térreo"
        );
        this.groupedAndares[1].items = this.crachas.filter(
          (cracha) => cracha.andar == "1º Andar"
        );
        this.groupedAndares[2].items = this.crachas.filter(
          (cracha) => cracha.andar == "2º Andar"
        );
        this.groupedAndares[3].items = this.crachas.filter(
          (cracha) => cracha.andar == "3º Andar"
        );
        this.groupedAndares[4].items = this.crachas.filter(
          (cracha) => cracha.andar == "4º Andar"
        );
      });
    },
    listarNomes() {
      this.visitanteService.listarNomes().then((data) => {
        this.nomesVisitantes = data;
      });
    },
    listarNomesDisponiveis() {
      this.visitanteService.listarNomesDisponiveis().then((data) => {
        this.nomesVisitantesDisponiveis = data;
      });
    },
    listarVisitas() {
      if (
        this.selectedNomesVisitantes === null ||
        this.selectedNomesVisitantes === ""
      ) {
        this.visitas = [];
      } else {
        this.visitaService.listar(this.selectedNomesVisitantes).then((data) => {
          if (data === null || data === "") {
            this.visitas = [];
          } else {
            this.visitas = data;
          }
        });
      }
    },
    formatarDatas(date) {
      if (date) {
        return Moment(date).format("DD/MM/YYYY h:mm:ss a");
      }
      return;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      (this.visita = {
        id: null,
        cracha: {
          id: null,
        },
        destino: null,
        motivo: null,
        visitante: {
          id: null,
        },
        dt_entrada: null,
      }),
        (this.titleDialog = "Nova Visita");
      this.submitted = false;
      this.visitaDialog = true;
    },
    hideDialog() {
      this.visitaDialog = false;
      this.submitted = false;
      this.visita = {};
    },
    sendVisita() {
      this.authService.checarToken(this.saveVisita());
    },
    saveVisita() {
      this.submitted = true;
      if (this.visita.id == null) {
        this.visita.visitante.id = this.selectedNomesVisitantes;
        this.visitaService
          .criar(this.visita)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Registro salvo com sucesso!",
              });
              this.listarVisitas(data.data.id);
              this.listarCrachas();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
            console.log(this.msgDesenvolvedor);
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: this.msgUsuario,
            });
          });
      } else {
        this.visita = {
          id: this.visita.id,
          cracha: this.visita.cracha,
          destino: this.visita.destino,
          motivo: this.visita.motivo,
          visitante: {
            id: this.selectedNomesVisitantes,
          },
          dt_entrada: this.visita.dt_entrada,
        };
        this.visitaService
          .atualizar(this.visita.id, this.visita)
          .then((data) => {
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Registro atualizado com sucesso!",
              });
              console.log(data);
              this.listarVisitas(data.data.id);
              this.hideDialog();
            }
          });
      }
    },
    editVisita(visita) {
      this.visita = visita;
      this.titleDialog = "Atualizar Visita";
      this.visitaDialog = true;
    },
    confirmdeleteVisita(visitante) {
      this.visitante = visitante;
      this.deleteVisitaDialog = true;
    },
    deleteVisita() {
      this.authService.checarToken(this.deletarVisita());
    },
    deletarVisita() {
      this.visitaService.remover(this.visitante.id).then((data) => {
        if (data.status === 204) {
          this.deleteVisitaDialog = false;
          this.visitante = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Registro Deletado.",
            life: 3000,
          });
          this.listarVisitas();
        } else {
          console.log(data);
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteVisitasDialog = true;
    },
    deleteSelectedVisitas() {
      for (var i in this.selectedVisitas) {
        this.visitante = this.selectedVisitas[i];
        this.deleteVisita();
      }
      this.deleteVisitasDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitante-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .visitante-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visitante-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.cracha-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.andar-1 {
    background: #c8e6c9;
    color: #256029;
  }
  &.andar-2 {
    background: cmyk(100, 0, 100, 0);
    color: #c63737;
  }
  &.andar-3 {
    background: #feedaf;
    color: #8a5340;
  }
  &.andar-3 {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>