import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class VisitaService extends AuthService {


  /* LISTAR TODOS */
  listar(visitante_id) {
    return axios({
      method: 'get',
      url: this.api_url + 'visita/' + visitante_id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* LISTAR TODOS */
  listarOndeSaidaNull() {
    return axios({
      method: 'get',
      url: this.api_url + 'visita/saidas',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* LISTAR QRCODES */
  obterQrCode(cracha) {
    return axios({
      method: 'get',
      url: this.api_url + 'visita/qrcode/' + cracha,
    });
  }
  /* CADASTRAR */
  criar(visita) {
    return axios({
      method: 'post',
      url: this.api_url + 'visita',
      withCredentials: true,
      data: visita,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'visita/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* ATUALIZAR */
  atualizar(id, visita) {
    return axios({
      method: 'put',
      url: this.api_url + 'visita/' + id,
      withCredentials: true,
      data: visita,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  sair(id) {
    return axios({
      method: 'put',
      url: this.api_url + 'visita/sair/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }

}
