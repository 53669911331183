import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class VisitanteService extends AuthService {


  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'visitante',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  listarNomes() {
    return axios({
      method: 'get',
      url: this.api_url + 'visitante/nomes',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  
  listarNomesDisponiveis() {
    return axios({
      method: 'get',
      url: this.api_url + 'visitante/nomes/disponiveis',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  /* LISTAR TODOS */
  buscarFoto(foto_id) {
    return axios({
      method: 'get',
      url: this.api_url + 'visitante/foto/' + foto_id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* CADASTRAR */
  criar(visitante) {
    return axios({
      method: 'post',
      url: this.api_url + 'visitante',
      withCredentials: true,
      data: visitante,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'visitante/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, visitante) {
    return axios({
      method: 'put',
      url: this.api_url + 'visitante/' + id,
      withCredentials: true,
      data: visitante,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
